module.exports = {
  //PARAMETERS
  developmentFeatures: true, //false or not assigned to disable development features
  captchaTokenFront: "6LcjXu0aAAAAABpuC2mj1E5H7dsJ5hQ5QThJkubd",
  stripe_pk: "pk_live_51KnkLDAucHUzuGBh4ir8LuIw15sg7ToYGKuUhGwbZanOQOGmyZzADxcAv4saay69AsomhjR7heIiPxnTiziG4i0I00evvlOBAN", //CITYSPACE PRODUCCION
  more_payment_methods: false,
  access_code_digits: 4,
  "color-primary": "#000C49",
  "color-map-nodisponible": "#cda077", // cda077 keep this color if the company doesnt ask to change it to their primary color
  useAnalytics: false,
  use_MERCHANT: false, //USE mercantilRegister param
  use_FAX: false,
  use_LOCATION_PREP: false,
  use_StorageGroups: true,
  use_TEST_DATA: false, //AUTO FILL FORMS WITH TEST DATA

  //FEATURES
  ALLOW_CHANGE_STORAGE: false, //ALLOW CHANGE STORAGE FEATURE
  
  //CHECKOUT PARAMETERS
  USE_RETENTION: false, //Allowed 19% retention
  use_PRORRATION: true, //SHOW PRORRATION AT CHECKOUT
  use_CONTRACTS: true, //SHOW SIGNABLE CONTRACTS AT CHECKOUT
  use_ASSURE: true, //SHOW ASSURES AT CHECKOUT
  use_ASSURE_PRORRATION: false, //APPLY PRORRATION TO ASSURES AT CHECKOUT
  use_ASSURE_EXTENSION: true, //SHOW ASSURE EXTENSION
  use_DEPOSIT: false, //SHOW DEPOSIT AT CHECKOUT
  
  //ADD CUSTOMER CHECKOUT PARAMETERS
  allow_CREATE_CUSTOMER_PURCHASE: true, //ALLOWS TO CREATE A SINGLE CUSTOMER WITHOUT PURCHASE
  allow_NORMAL_PURCHASE: true, //ALLOWS NORMAL PURCHASE
  allow_LACK_PURCHASE: true, //ALLOWS LACK PURCHASE
  allow_BUY_PRODUCTS_PURCHASE: true, //ALLOWS BUY PRODUCTS PURCHASE
  allow_BOOKING_PURCHASE: false, //ALLOWS BOOKING PURCHASE

  //PAYMENT PARAMETERS
  use_CARD_PAYMENT_FOR_WORKERS: true, //ENABLE CARD PAYMENTS
  use_SEPA_DEBIT_PAYMENT_FOR_WORKERS: true, //ENABLE SEPA_DEBIT PAYMENTS
  use_TRANSFER_PAYMENT_FOR_WORKERS: true, //ENABLE TRANSFER PAYMENTS
  use_CARD_PAYMENT_FOR_CUSTOMERS: true, //ENABLE CARD PAYMENTS
  use_SEPA_DEBIT_PAYMENT_FOR_CUSTOMERS: false, //ENABLE SEPA_DEBIT PAYMENTS
  use_TRANSFER_PAYMENT_FOR_CUSTOMERS: false, //ENABLE TRANSFER PAYMENTS
  use_PAY_AT_SHOP: true, //ENABLE TRANSFER PAYMENTS

  //PARAM NEW COMPANY
  withLanding: false,
  withIndoorOpening: true, //tiene puerta principal interna
  withStorageOpening: true, //storages doors

  companyName: "City Space Self Storage, SL",
  phone: "674 285 997",
  city: "Gernika",
  province: "Vizcaya",
  email: "info@trastelekugernika.com",
  address: "Pg/Industrial Bekoibarra, edificio solluble, 25-A Gernika, Vizcaya",
  postal_code: "48300",
  storageNaming: "Trastero",
  commercialName: "City Space Self Storage, SL",
  payments_companyName: "City Space Self Storage, SL",
  payments_NIF: "B67783456",
  mercantilRegister: "",
  accountant_email: "info@trastelekugernika.com",
  contract_email: "info@trastelekugernika.com",
  url: "https://trasteroscityspace.com/",
  branch: 'CITY_SPACE',

  //INVOICE PARAMETERS
  firstSizeM3: false,
  use_IRPF: false,
  IVA: 21, //IN PERCENT 21%
  RETENTION: 19, //IN PERCENT 19%
};
